"use client";

import { ColumnDef } from "@tanstack/react-table";

import { ArrowUpDown } from "lucide-react";
import CellWithNavigation from "./cellWithnavigation";
import { Button } from "../../../../../components/button";

// This type is used to define the shape of our data.
// You can use a Zod schema here if you want.
export type User = {
  userName: string;
  email: string;
  status: string;
  rolename: string;
};

export const columns: ColumnDef<User>[] = [
  {
    accessorKey: "userName",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Name
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      const user = row.original;
      return (
        <CellWithNavigation instanceId="">{user.userName}</CellWithNavigation>
      );
    },
  },
  {
    accessorKey: "email",
    header: () => <div>Email</div>,
    cell: ({ row }) => {
      const user = row.original;
      return (
        <CellWithNavigation instanceId="">{user.email}</CellWithNavigation>
      );
    },
  },
  {
    accessorKey: "rolename",
    header: () => <div>Role</div>,
    cell: ({ row }) => {
      const user = row.original;
      return (
        <CellWithNavigation instanceId="">{user.rolename}</CellWithNavigation>
      );
    },
  },
  {
    accessorKey: "status",
    header: () => <div>Status</div>,
    cell: ({ row }) => {
      const user = row.original;
      return (
        <CellWithNavigation instanceId={``}>
          {user.status ? "Active" : "InActive"}
        </CellWithNavigation>
      );
    },
  },
];
