import React, { useEffect } from "react";
import coAiLogo from "../../../../../assets/co-ai-logo.png";

import rosAiLogo from "../../../../../assets/big-logo.png";
import {
  DatabaseOutlined,
  HomeOutlined,
  LaptopOutlined,
} from "@ant-design/icons";
import Login from "../components/login";
import "./login.css";
import { useNavigate } from "react-router-dom";
import { Badge } from "antd";
export default function LoginViewAutoDesign() {
  const navigate = useNavigate();

  return (
    <div className="h-screen flex flex-row ">
      <div className=" flex flex-col h-screen w-[65%] bg-[#0A4378] justify-between ">
        <div
          className="ml-10 mt-4 border border-white max-w-[7%] rounded cursor-pointer "
          onClick={() => navigate("/")}
        >
          <div className="text-[#ffffff] p-2">
            <HomeOutlined /> Home
          </div>
        </div>
        <div className="flex flex-col  justify-center items-center mb-52">
          <div className="w-[50%] ml-10">
            <img src={coAiLogo} alt="Logo" />
          </div>
          {/* <div className="text-white"> */}
          {/* Think Big, Execute Easily, Integrate Continuously & Reduce TAT */}
          {/* </div> */}
          <div className="pr-10">
            <Badge color="gold" count={"Pro"}>
              <div className=" font-bold text-xl text-white p-2">
                Container Vision
              </div>
            </Badge>
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-between w-[35%]">
        <div className="flex flex-row justify-between">
          <div></div>
          <img
            className="h-[50px] w-[100px] items-end mr-2"
            src={rosAiLogo}
            alt="Logo"
          />
        </div>
        <div className="w-100">
          <Login />
        </div>
        <div className="flex flex-row justify-center">
          <footer>
            <span
              style={{
                pointerEvents: "none",
                color: "grey",
                textDecoration: "none",
              }}
            >
              <LaptopOutlined /> {localStorage.getItem("version")}
            </span>{" "}
            <span
              style={{
                pointerEvents: "none",
                color: "grey",
                textDecoration: "none",
              }}
            >
              {" "}
              <DatabaseOutlined /> 2.1
            </span>
          </footer>
        </div>
      </div>
    </div>
  );
}
