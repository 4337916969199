"use client";

import { ColumnDef } from "@tanstack/react-table";

import { ArrowUpDown } from "lucide-react";
import CellWithNavigation from "./cellWithnavigation";
import { Button } from "../../../../../../components/button";

// This type is used to define the shape of our data.
// You can use a Zod schema here if you want.
export type Instance = {
  instance_id: string;
  train_id: string;
  station_id: string;
  created_at: string;
};

export const columns: ColumnDef<Instance>[] = [
  {
    accessorKey: "instance_id",
    header: () => <div>Instance ID</div>,
    cell: ({ row }) => {
      const instance = row.original;
      return (
        <CellWithNavigation
          instanceId={`${instance.instance_id}/${instance.station_id}/${instance.train_id}`}
        >
          {instance.instance_id}
        </CellWithNavigation>
      );
    },
  },
  {
    accessorKey: "train_id",
    header: () => <div>Train ID</div>,
    cell: ({ row }) => {
      const instance = row.original;
      return (
        <CellWithNavigation
          instanceId={`${instance.instance_id}/${instance.station_id}/${instance.train_id}`}
        >
          {instance.train_id}
        </CellWithNavigation>
      );
    },
  },
  {
    accessorKey: "station_id",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Mast ID
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      const instance = row.original;
      return (
        <CellWithNavigation
          instanceId={`${instance.instance_id}/${instance.station_id}/${instance.train_id}`}
        >
          {instance.station_id}
        </CellWithNavigation>
      );
    },
  },
  {
    accessorKey: "created_at",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => {
            const isSorted = column.getIsSorted();

            if (!isSorted) {
              column.toggleSorting(true);
            } else if (isSorted === "desc") {
              column.toggleSorting(false);
            } else {
              column.toggleSorting(true);
            }
          }}
        >
          Created At
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      const instance = row.original;
      const formatDate = (isoString: any) => {
        const date = new Date(isoString);
        const formattedDate = date.toLocaleString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });
        return formattedDate;
      };
      return (
        <CellWithNavigation
          instanceId={`${instance.instance_id}/${instance.station_id}/${instance.train_id}`}
        >
          {formatDate(instance.created_at)}
        </CellWithNavigation>
      );
    },
  },
];
