import { useNavigate } from "react-router-dom";

type CellWithNavigationProps = {
  instanceId: string;
  children: React.ReactNode;
};

const CellWithNavigation = ({
  instanceId,
  children,
}: CellWithNavigationProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    // Navigate to the desired route with the instanceId
    navigate(`/manage-instance/${instanceId}`);
  };

  return (
    <div onClick={handleClick} style={{ cursor: "pointer" }}>
      {children}
    </div>
  );
};

export default CellWithNavigation;
