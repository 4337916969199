import axios from "axios";
import React, { useEffect, useState } from "react";
import { DataTable } from "../components/data-table";
import { columns } from "../components/columns";
import { Modal, Spin } from "antd";
import { Button } from "../../../../../components/button";
import { PlusIcon } from "lucide-react";
import UserForm from "../components/user_form";
import { APIStandards } from "../../../../../lib/api_standards";

type Props = {};

function UserScreen({}: Props) {
  const [usersList, setUsersList] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isuserCreated, setIsUserCreated] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      const res = await axios.get(APIStandards.ADMIN.LIST_USER);
      const data = res.data;
      setUsersList(data.users);
      setIsLoading(false);
    })();
  }, [isuserCreated]);

  return (
    <div className=" mx-4 px-4 py-10 max-h-[100vh] min-h-[100vh] overflow-y-scroll">
      <Modal
        title="Create User"
        open={isModalOpen}
       closable
        footer={null}
      >
        <UserForm
          setisOpenModel={setIsModalOpen}
          setisuserCreated={setIsUserCreated}
        />
      </Modal>
      {isLoading && (
        <div className="flex mx-auto items-center min-h-[85vh] max-h-[85vh] justify-center">
          <Spin />
        </div>
      )}
      {!isLoading && (
        <div className="flex flex-col gap-5 ">
          <div className="flex flex-row justify-between">
            <div className="flex flex-col">
              <div className=" text-3xl font-bold tracking-wide">
                Users ({usersList.length})
              </div>
              <div>Create and manage users</div>
            </div>
            <div>
              <Button
                className="bg-[#0A4378]"
                onClick={() => setIsModalOpen(true)}
              >
                <div className="flex flex-row items-center justify-center">
                  <div>
                    <PlusIcon />
                  </div>
                  <div>Create New</div>
                </div>
              </Button>
            </div>
          </div>
          <div>
            {" "}
            <DataTable columns={columns} data={usersList} />
          </div>
        </div>
      )}
    </div>
  );
}

export default UserScreen;
