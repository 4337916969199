import React, { useRef, useState } from "react";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { Button, Input, Typography, Spin, message } from "antd";
import axios from "axios";

const { Title, Text } = Typography;

const DataCollectionOld = () => {
  // const getCurrentDateTime = () => {
  //   const now = new Date();
  //   return now.toISOString().replace(/:/g, "-").replace(/\..+/, "");
  // };
  const imgRef: any = useRef(null);
  // const frameCountRef: any = useRef(0);
  // const zipRef: any = useRef(new JSZip());
  // const folderRef: any = useRef(
  //   zipRef.current.folder(`frames-${getCurrentDateTime()}`)
  // );
  // const blobPromisesRef: any = useRef([]);

  const [capturing, setCapturing] = useState(false);
  // const [fps, setFps] = useState(30);
  // const [captureInterval, setCaptureInterval] = useState<any>(null);
  const [statusMessage, setStatusMessage] = useState("");
  // const [totalBlobSize, setTotalBlobSize] = useState(0);
  const toggleCapture = async () => {
    if (capturing) {
      setCapturing(false);
      message.info("Data Collected");
      const res = await axios.post(
        `http://192.168.1.235:5000/set_save_local?save=false`
      );
      message.info(res.data.toString());
    } else {
      setCapturing(true);
      message.info("Capturing started...");
      // startCapture();
      const res = await axios.post(
        `http://192.168.1.235:5000/set_save_local?save=true`
      );
      message.info(res.data.toString());
    }
  };
  // const toggleCapture = () => {
  //   if (capturing) {
  //     clearInterval(captureInterval);
  //     setCapturing(false);
  //     setStatusMessage("Stopping capture...");

  //     Promise.all(blobPromisesRef.current)
  //       .then(() => {
  //         if (frameCountRef.current > 0) {
  //           setStatusMessage("Generating ZIP file...");
  //           const zipFileName = `frames-${getCurrentDateTime()}.zip`;
  //           zipRef.current
  //             .generateAsync({ type: "blob" })
  //             .then((content: any) => {
  //               saveAs(content, zipFileName);
  //               resetCapture();
  //               setStatusMessage("Capture stopped. ZIP file generated.");
  //             })
  //             .catch((error: any) => {
  //               console.error("Error generating ZIP:", error);
  //               setStatusMessage("Error generating ZIP file.");
  //             });
  //         } else {
  //           setStatusMessage("No frames captured.");
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error processing blob promises:", error);
  //         setStatusMessage("Error during processing.");
  //       });
  //   } else {
  //     setCapturing(true);
  //     setStatusMessage("Capturing started...");
  //     startCapture();
  //   }
  // };

  // const startCapture = () => {
  //   const intervalId = setInterval(() => {
  //     if (!imgRef.current || !imgRef.current.complete) return;

  //     const canvas = document.createElement("canvas");
  //     canvas.width = imgRef.current.naturalWidth;
  //     canvas.height = imgRef.current.naturalHeight;
  //     const ctx: any = canvas.getContext("2d");

  //     ctx.drawImage(imgRef.current, 0, 0);
  //     const blobPromise = new Promise<void>((resolve) => {
  //       canvas.toBlob((blob) => {
  //         if (blob) {
  //           const blobSize = blob.size;
  //           setTotalBlobSize((prev) => prev + blobSize);
  //           folderRef.current.file(`frame-${frameCountRef.current}.png`, blob);
  //           frameCountRef.current++;
  //         }
  //         resolve();
  //       }, "image/png");
  //     });

  //     blobPromisesRef.current.push(blobPromise);
  //   }, 1000 / fps);

  //   setCaptureInterval(intervalId);
  // };

  // const resetCapture = () => {
  //   zipRef.current = new JSZip();
  //   folderRef.current = zipRef.current.folder(`frames-${getCurrentDateTime()}`);
  //   frameCountRef.current = 0;
  //   blobPromisesRef.current = [];
  //   setTotalBlobSize(0);
  //   setStatusMessage("");
  // };
  // const MAX_ZIP_SIZE = 1073741; // 1 GB in bytes

  // const startCapture = () => {
  //   const intervalId = setInterval(() => {
  //     if (!imgRef.current || !imgRef.current.complete) return;

  //     const canvas = document.createElement("canvas");
  //     canvas.width = imgRef.current.naturalWidth;
  //     canvas.height = imgRef.current.naturalHeight;
  //     const ctx: any = canvas.getContext("2d");

  //     ctx.drawImage(imgRef.current, 0, 0);
  //     canvas.toBlob((blob) => {
  //       if (blob) {
  //         const blobSize = blob.size;
  //         setTotalBlobSize((prev) => prev + blobSize);

  //         folderRef.current.file(`frame-${frameCountRef.current}.png`, blob);
  //         frameCountRef.current++;

  //         // Check if total size exceeds limit

  //         if (totalBlobSize / 1024 >= MAX_ZIP_SIZE) {
  //           finalizeCurrentZip();
  //           resetCapture(); // Prepare for next zip
  //         }
  //       }
  //     }, "image/png");
  //   }, 1000 / fps);

  //   setCaptureInterval(intervalId);
  // };

  // const finalizeCurrentZip = () => {
  //   message.info("Generating");
  //   const zipFileName = `frames-${getCurrentDateTime()}.zip`;
  //   zipRef.current
  //     .generateAsync({ type: "blob" })
  //     .then((content: any) => {
  //       saveAs(content, zipFileName);
  //       console.log(`Generated ${zipFileName}`);
  //       message.info("Generated");
  //     })
  //     .catch((error: any) => {
  //       console.error("Error generating ZIP:", error);
  //       setStatusMessage("Error generating ZIP file.");
  //     });
  // };

  // const resetCapture = () => {
  //   zipRef.current = new JSZip();
  //   folderRef.current = zipRef.current.folder(`frames-${getCurrentDateTime()}`);
  //   frameCountRef.current = 0;
  //   blobPromisesRef.current = [];
  //   setTotalBlobSize(0);
  // };

  // const handleImageLoad = () => {
  //   if (capturing) {
  //     startCapture();
  //   }
  // };

  return (
    <div className="flex flex-col items-center p-6 bg-gray-100 min-h-screen">
      {/* <Title level={2} className="mb-4">
        Data Collection
      </Title> */}
      <div className="relative w-full mb-4">
        <img
          ref={imgRef}
          src="http://localhost:5000/video_feed"
          alt="Stream"
          className="w-full rounded-lg shadow-lg items-center mx-auto"
          crossOrigin="anonymous"
          // onLoad={handleImageLoad}
          onError={(e: any) => {
            e.target.src =
              "https://cdn-icons-png.flaticon.com/512/12391/12391605.png";
            e.target.style.width = `50%`;
          }}
        />
        {capturing && (
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-lg">
            <Spin size="large" />
          </div>
        )}
      </div>

      <div className="flex flex-col md:flex-row md:justify-between w-full mb-4">
        {/* <div className="flex items-center mb-2 md:mb-0">
          <Text className="mr-2">Frames per second:</Text>
          <Input
            type="number"
            value={fps}
            onChange={(e) => setFps(Number(e.target.value))}
            className="w-24"
            min={1}
          />
        </div> */}
        <Button
          type="primary"
          onClick={toggleCapture}
          className={`w-full md:w-auto ${
            capturing ? "bg-red-500" : "bg-blue-500"
          }`}
        >
          {capturing ? "Stop Capture" : "Start Capture"}
        </Button>
      </div>

      {/* <div className="text-center mt-4">
        <Text>Status: {statusMessage}</Text>
        <div className="mt-2">
          <Text>Frames Captured: {frameCountRef.current}</Text>
        </div>
        <div>
          <Text>Total Size: {(totalBlobSize / 1024).toFixed(2)} KB</Text>
        </div>
      </div> */}
    </div>
  );
};

export default DataCollectionOld;
