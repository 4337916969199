import React, { useEffect, useRef, useState } from "react";

import { Hypnosis } from "react-cssfx-loading";

import {
  BotIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  PlusIcon,
  RefreshCcw,
  SendIcon,
  Trash2Icon,
  UploadCloudIcon,
} from "lucide-react";
import JSZip from "jszip";
import FileSaver from "file-saver";
import { LazyLoadImage } from "react-lazy-load-image-component";

import placeholder from "../../../../../../assets/placeholder.jpg";
import UserBubble from "../chatBubbles/userbubble";
import AIBubble from "../chatBubbles/aibubble";
import { Descriptions, Empty, message, Spin } from "antd";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  VideoToFrames,
  VideoToFramesMethod,
} from "../../../../../../lib/VideoToFrames";
import { Button } from "../../../../../../components/button";
import { APIStandards } from "../../../../../../lib/api_standards";

type Props = {};

function CollectedDataImages({ type_no }: any) {
  const [media, setMedia] = useState<any>([]);
  const [selectedMedia, setSelectedMedia] = useState<any>(null);
  const [frames, setFrames] = useState([]);
  const [currentFrame, setCurrentFrame] = useState(null);
  const [loading, setLoading] = useState(false);
  const [mediaType, setMediaType] = useState<string>("");
  const [messages, setMessages] = useState<any[]>([]);
  const [chatInput, setChatInput] = useState("");
  const fileInputRef = useRef<any>();
  const [isRequestOngoing, setIsRequestOngoing] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [instanceDetails, setInstanceDetails] = useState<any>([]);
  const [currentFrameDetails, setCurrentFrameDetails] = useState<any>();
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const { id, station_id, train_id } = useParams<{
    id: string;
    station_id: string;
    train_id: string;
  }>();
  const [isDataCollectionDetailPage, setisDataCollectionDetailPage] =
    useState(false);
  useEffect(() => {
    if (id && id?.startsWith("datacollection")) {
      setisDataCollectionDetailPage(true);
    }
  }, [id]);
  const handleDownloadAll = async () => {
    const zip = new JSZip();
    const folder: any = zip.folder("images");

    instanceDetails.forEach((item: any, index: any) => {
      const base64Data = item;
      const imageName = `image_${index + 1}.png`;

      folder.file(imageName, base64Data, { base64: true });
    });

    const content = await zip.generateAsync({ type: "blob" });

    FileSaver.saveAs(content, `${id}.zip`);
  };
  // useEffect(() => {
  //   (async () => {
  //     const res = await axios.get(
  //       `http://localhost:8000/api/container_vision/prediction/?date=all&instance_id=${id}`
  //     );
  //     const data = res.data;
  //     console.log(data);
  //     var imageList: any = [];
  //     setInstanceDetails(data);

  //     // data.forEach((predData: any) => {
  //     //   console.log(predData);
  //     //   imageList.push(predData.image_data);
  //     //   // setMedia((prevMedia: any) => [...prevMedia, ...predData.image_data]);
  //     // });
  //     // setMedia(imageList);
  //     setIsLoading(false);
  //   })();
  // }, []);
  const fetchImages = async (pageNumber: number) => {
    setIsLoading(true);
    try {
      // const res = await axios.get(
      //   `http://localhost:8000/api/container_vision/prediction/?date=all&instance_id=${id}&page=${pageNumber}`
      // );
      const res = await axios.get(
        `${APIStandards.APP.LIST_COLLECTED_IMAGES}/?instance_id=${id}&type=${type_no}&page=${pageNumber}`
      );
      const data = res.data;
      console.log(data);
      setTotalCount(data.count);
      setInstanceDetails((prev: any) => [...prev, ...data.results["images"]]); // Assuming your response has a results field
      setHasMore(data.next !== null); // Check if there's more data
    } catch (error) {
      console.error("Error fetching images:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (page == 1 && instanceDetails.length > 0) {
      setInstanceDetails([]);
    } else {
      fetchImages(page);
    }
  }, [page]);

  const handleLoadMore = () => {
    if (hasMore && !isLoading) {
      setPage((prev) => prev + 1);
    }
  };
  useEffect(() => {
    if (instanceDetails && instanceDetails.length > 0) {
      setCurrentFrameDetails(instanceDetails[0]);
      console.log(currentFrameDetails);
    }
  }, [instanceDetails]);
  const handleMediaSelect = async (event: any) => {
    const files: any = Array.from(event.target.files);
    setMedia((prevMedia: any) => [...prevMedia, ...files]);

    const firstImage = files.find((file: { type: any }) =>
      file.type.startsWith("image")
    );
    if (firstImage) {
      setMediaType("image");
      setSelectedMedia(firstImage);
    } else {
      const firstVideo = files.find((file: { type: any }) =>
        file.type.startsWith("video")
      );
      if (firstVideo) {
        setMediaType("video");
        setSelectedMedia(firstVideo);
        await extractFrames(event.target.files);
      }
    }
  };
  function dataURItoBlob(dataURI: any) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);
    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }
  const handleSendClick = async () => {
    if (chatInput.trim() !== "") {
      setMessages((prevMessages: any) => [
        ...prevMessages,
        { type: "user", text: chatInput },
      ]);
      if (!isRequestOngoing) {
        // Add a placeholder message to the state if no request is ongoing
        setMessages((prevMessages) => [
          ...prevMessages,
          { type: "ai", text: "......." }, // Initial empty message for AI response
        ]);
      }
      const formData = new FormData();
      var imageData;
      if (mediaType === "image") {
        imageData = media[currentIndex];
      } else {
        imageData = dataURItoBlob(frames[currentIndex]);
      }
      formData.append("image", imageData);
      formData.append("prompt", chatInput);
      setChatInput("");
      setIsRequestOngoing(true);
      try {
        const response: any = await fetch(APIStandards.APP.TRANSCRIBE, {
          method: "POST",
          body: formData,
        });

        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let result = "";

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          result += decoder.decode(value, { stream: true });
          setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages];
            updatedMessages[updatedMessages.length - 1].text = result;
            return updatedMessages;
          });
        }
      } catch (error) {
        console.error("Error during API call:", error);
      } finally {
        setIsRequestOngoing(false); // Reset the flag when the request is complete
      }
    }
  };
  const sendfromai = (aimessage: any) => {
    setMessages((prevMessages: any) => [
      ...prevMessages,
      { type: "ai", text: aimessage },
    ]);
  };
  const extractFrames = async (videoFile: any) => {
    setLoading(true);
    const [file] = videoFile;
    const fileUrl = URL.createObjectURL(file);
    const frames: any = await VideoToFrames.getFrames(
      fileUrl,
      5,
      VideoToFramesMethod.fps
    );

    setFrames(frames);
    setCurrentFrame(frames[0]);
    setLoading(false);
  };

  const handleFrameClick = (frame: any) => {
    if (currentIndex != frame) {
      setCurrentIndex(frame);
      setMessages([]);
    }
  };
  const handleMediaClick = (index: any, item: any) => {
    // setCurrentFrameDetails(item);
    if (currentIndex != index) {
      setCurrentIndex(index);
      setMessages([]);
    }
  };
  const handleDeleteImage = (index: number) => {
    setMedia((prevMedia: any) =>
      prevMedia.filter((_: any, i: any) => i !== index)
    );
    if (index === 0) {
      if (media.length === 1) {
        console.log(media.length);
        handleDeleteAll();
      } else {
        setCurrentIndex(0);
      }
    } else {
      setCurrentIndex(index - 1);
    }
  };
  const handleDeleteAll = () => {
    setMedia([]);
    setSelectedMedia(null);
    setFrames([]);
    setCurrentFrame(null);
    setMediaType("");
    setCurrentIndex(0);
    setMessages([]);
  };

  const handleImageClick = () => {
    console.log(fileInputRef.current);
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
    console.log(fileInputRef.current);
  };

  const handlePrevClick = (mediaTypes: any) => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0
        ? (mediaTypes === "image" ? instanceDetails.length : frames.length) - 1
        : prevIndex - 1
    );
  };

  const handleNextClick = (mediaTypes: any) => {
    setCurrentIndex((prevIndex) =>
      prevIndex ===
      (mediaTypes === "image" ? instanceDetails.length : frames.length) - 1
        ? 0
        : prevIndex + 1
    );
  };
  useEffect(() => {
    if (instanceDetails && instanceDetails.length > 0) {
      setCurrentFrameDetails(instanceDetails[currentIndex]);
    }
  }, [currentIndex]);
  return (
    <div className="flex flex-row h-[89vh]">
      <div className="w-[65%] h-full flex flex-col p-5 ">
        <div className="min-w-full max-w-full max-h-[60%] min-h-[60%]">
          {(isLoading || instanceDetails.length < 0) && (
            <>
              <div className="flex items-center justify-center mx-auto my-auto max-h-full min-h-full min-w-full max-w-full">
                <Hypnosis width="100px" height="100px" />
              </div>
            </>
          )}
          {/* {loading && (
            <div className="flex items-center justify-center mx-auto my-auto max-h-full min-h-full min-w-full max-w-full">
              <Hypnosis width="100px" height="100px" />
            </div>
          )} */}
          {!isLoading && instanceDetails && instanceDetails.length > 0 && (
            <div className="relative inline-block h-[100%] w-[100%] ">
              <LazyLoadImage
                src={`data:image/png;base64, ${instanceDetails[currentIndex]}`}
                alt="Selected Media"
                className="rounded-md max-h-full min-h-full min-w-full max-w-full object-contain"
              />
            </div>
          )}

          <div className="flex flex-row overflow-x-scroll mt-40">
            {instanceDetails.map((mediaItem: any, index: any) => (
              <div
                key={index}
                className="relative min-w-[30%] max-w-[30%] pr-2 pb-2"
                onClick={() => setCurrentIndex(index)}
              >
                <LazyLoadImage
                  src={`data:image/png;base64, ${mediaItem}`}
                  alt={`Media ${index}`}
                  className="rounded-md max-h-full min-h-full min-w-full max-w-full object-cover"
                />
              </div>
            ))}
          </div>

          {selectedMedia &&
            selectedMedia.type.startsWith("video") &&
            currentFrame && (
              <div className="relative h-[100%] w-[100%] ">
                <Button
                  variant="ghost"
                  className="absolute h-[50px] w-[50px] left-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white rounded-full p-2 shadow-md hover:bg-opacity-70"
                  disabled={currentIndex === 0}
                  onClick={() => handlePrevClick("video")}
                >
                  <ChevronLeftIcon />
                </Button>
                <img
                  src={frames[currentIndex]}
                  alt="Selected Media"
                  className="rounded-md max-h-full min-h-full min-w-full max-w-full object-contain"
                />
                <Button
                  variant="ghost"
                  className="absolute  h-[50px] w-[50px] right-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white rounded-full p-2 shadow-md hover:bg-opacity-70"
                  disabled={currentIndex === frames.length - 1}
                  onClick={() => {
                    handleNextClick("video");
                  }}
                >
                  <ChevronRightIcon />
                </Button>
              </div>
            )}
        </div>
        {
          <div className="flex flex-row gap-2 justify-end items-end mt-10">
            <div className="flex justify-center mt-4">
              <Button
                onClick={() => {
                  setPage(1);
                  fetchImages(1);
                }}
                disabled={isLoading}
              >
                <div className="flex flex-row gap-1 items-center">
                  <div>
                    <RefreshCcw />
                  </div>
                  <div>Refresh</div>
                </div>
              </Button>
            </div>
            <div>
              {hasMore && (
                <div className="flex justify-center mt-4">
                  <Button onClick={handleLoadMore} disabled={isLoading}>
                    Load More
                  </Button>
                </div>
              )}
            </div>
            <div>
              <Button onClick={handleDownloadAll}>Download All</Button>
            </div>
          </div>
        }
        <div className="flex flex-row overflow-x-scroll min-w-full max-w-full max-h-[20%] min-h-[20%]  mt-40">
          {instanceDetails && instanceDetails.length < 1 && (
            <div className="relative min-w-[30%] max-w-[30%] max-h-full min-h-full pr-2 pb-2">
              <img
                src={placeholder}
                alt={`Media Placeholder`}
                className="rounded-md max-h-full min-h-full min-w-full max-w-full object-cover"
              />
            </div>
          )}
          {/* {!isLoading &&
            instanceDetails &&
            instanceDetails.length > 0 &&
            instanceDetails.map((mediaItem: any, index: any) => (
              <div
                key={index}
                className="relative min-w-[30%] max-w-[30%] max-h-full min-h-full pr-2 pb-2"
                onClick={() => handleMediaClick(index, mediaItem)}
              >
                <img
                  key={index}
                  src={`data:image/png;base64, ${mediaItem.image_data}`}
                  alt={`Media ${index}`}
                  className="rounded-md max-h-full min-h-full min-w-full max-w-full object-cover"
                />
              </div>
            ))} */}
          {selectedMedia &&
            selectedMedia.type.startsWith("video") &&
            frames.map((frame, index) => (
              <div
                key={index}
                className="min-w-[30%] max-w-[30%] max-h-full min-h-full pr-2 pb-2"
                onClick={() => handleFrameClick(index)}
              >
                <img
                  src={frame}
                  alt={`Frame ${index}`}
                  className="rounded-md max-h-full min-h-full min-w-full max-w-full object-cover"
                />
              </div>
            ))}
        </div>
      </div>
      <div className="mt-5">
        {/* <Separator orientation="vertical" color="black" /> */}
      </div>
      <div className="min-h-[95%] w-[35%]  flex flex-col gap-2">
        <div className="w-full h-ful mr-2 rounded flex flex-col  mt-5 shadow-sm border ">
          <header className="bg-[#0A4378] text-primary-foreground py-4 px-6 flex items-center justify-between rounded-t-md ">
            <div className="text-lg font-bold">Instance Details</div>
            <div className="flex items-center gap-4">
              <Button variant="ghost" size="icon">
                <BotIcon className="w-5 h-5" />
              </Button>
            </div>
          </header>
          <div className="flex-1 overflow-auto p-6">
            {!isLoading && currentFrameDetails && (
              <Descriptions
                bordered
                layout="horizontal"
                column={1}
                className="mt-4"
                items={[
                  {
                    key: "1",
                    label: "Instance ID",

                    children: id,
                  },
                  {
                    key: "2",
                    label: "Train ID",
                    children: train_id,
                  },
                  {
                    key: "3",

                    label: "Station ID",
                    children: station_id,
                  },
                  {
                    key: "4",

                    label: "Total Image Count",
                    children: totalCount,
                  },
                ]}
              />
            )}
            {isLoading && <Spin />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CollectedDataImages;
