import { useEffect, useState } from "react";

import axios from "axios";
import { DataTable } from "../components/table/data-table";
import { columns } from "../components/table/columns";
import { Spin } from "antd";
import { useLocation } from "react-router-dom";
import { APIStandards } from "../../../../../lib/api_standards";

// async function getData(): Promise<Instance[]> {

//   // Fetch data from your API here.
//   return InstanceList;
// }

// export default async function InstanceList2() {
// const data = await getData();

// return (
//   <div className="container mx-auto py-10">
//     <DataTable columns={columns} data={data} />
//   </div>
// );
// }

function InstanceList2() {
  const [InstanceList, setInstanceList] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const pathname = useLocation();
  useEffect(() => {
    (async () => {
      const res = await axios.get(APIStandards.APP.GET_INSTANCES);
      const data = res.data;

      const sortedItems = data.sort((a: any, b: any) => {
        return (
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
      });
      setInstanceList(sortedItems);
      setIsLoading(false);
    })();
  }, []);

  return (
    <div
      className={` mx-4 py-10 ${
        pathname.pathname === "/admin/instances"
          ? "min-h-[100vh] max-h-[100vh] overflow-y-auto"
          : ""
      }`}
    >
      {isLoading && (
        <div className="flex mx-auto items-center min-h-[85vh] max-h-[85vh] justify-center">
          <Spin />
        </div>
      )}
      {!isLoading && (
        <div className={`flex flex-col gap-5 `}>
          <div className="flex flex-row justify-between">
            <div className=" text-3xl font-bold tracking-wide">
              Instances ({InstanceList.length})
            </div>
          </div>
          <div>
            {" "}
            <DataTable columns={columns} data={InstanceList} />
          </div>
        </div>
      )}
    </div>
  );
}

export default InstanceList2;
