import { Col, Layout, Row } from "antd";
import React, { useEffect, useState } from "react";

import { Outlet, useLocation } from "react-router-dom";
import HeaderComponent from "../../components/header";
import { SideBar } from "./features/dashboard/components/sidebar";

export default function MasterAdmin() {
  const router = useLocation();

  return (
    <Layout
      style={{ backgroundColor: "#fff" }}
      className="max-h-[100vh] min-h-[100vh]"
    >
      <div className="flex flex-row w-full max-h-[100vh] min-h-[100vh]">
        <div>
          <SideBar />
        </div>
        <div className="w-full max-h-[100vh] min-h-[100vh] ">
          <Outlet />
        </div>
      </div>
      {/* <div className="h-[5vh]">
        <HeaderComponent />
      </div> */}
    </Layout>
  );
}
