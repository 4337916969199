
import React from "react";

export const Footer: React.FC = () => {
  
  return (
    <footer
      style={{
        textAlign: 'center',
        // padding: '10px',
        // marginTop: '150px',
        backgroundColor: '#ffffff',
        color: 'gray-300',
        position: 'relative',
        bottom: '10px',
        width: '100%',
        fontSize: '14px',
      }}
    >
      <p>Copyrights &copy; {new Date().getFullYear()} RoshAi. All Rights Reserved.</p>
    </footer>
  );
};
