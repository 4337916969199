import React, { useEffect, useRef, useState } from "react";

import { Hypnosis } from "react-cssfx-loading";

import {
  BotIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  PlusIcon,
  SendIcon,
  Trash2Icon,
  UploadCloudIcon,
} from "lucide-react";

import placeholder from "../../../../../../assets/placeholder.jpg";
import UserBubble from "../chatBubbles/userbubble";
import AIBubble from "../chatBubbles/aibubble";
import { Descriptions, Empty, message } from "antd";
import axios from "axios";
import {
  VideoToFrames,
  VideoToFramesMethod,
} from "../../../../../../lib/VideoToFrames";
import { Button } from "../../../../../../components/button";
import { Textarea } from "../../../../../../components/textarea";
import { APIStandards } from "../../../../../../lib/api_standards";

type Props = {};

function ImportTab({}: Props) {
  const [media, setMedia] = useState<any>([]);
  const [selectedMedia, setSelectedMedia] = useState<any>(null);
  const [frames, setFrames] = useState([]);
  const [currentFrame, setCurrentFrame] = useState(null);
  const [loading, setLoading] = useState(false);
  const [mediaType, setMediaType] = useState<string>("");
  const [messages, setMessages] = useState<any[]>([]);
  const [extractedData, setExtractedData] = useState<any>();
  const [chatInput, setChatInput] = useState(
    "Provide me container number, type,dimensions, hazard details and wagon number under the container. dont give any other text give exactly with label and the value as ol list and if some content is not available mention not available with the label"
  );
  const fileInputRef = useRef<any>();
  const [isRequestOngoing, setIsRequestOngoing] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [mediaMessages, setMediaMessages] = useState<{ [key: number]: any }>(
    {}
  );
  const handleMediaSelect = async (event: any) => {
    const files: any = Array.from(event.target.files);
    setMedia((prevMedia: any) => [...prevMedia, ...files]);

    const firstImage = files.find((file: { type: any }) =>
      file.type.startsWith("image")
    );
    if (firstImage) {
      setMediaType("image");
      setSelectedMedia(firstImage);
    } else {
      const firstVideo = files.find((file: { type: any }) =>
        file.type.startsWith("video")
      );
      if (firstVideo) {
        setMediaType("video");
        setSelectedMedia(firstVideo);
        await extractFrames(event.target.files);
      }
    }
  };
  function dataURItoBlob(dataURI: any) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);
    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

  const sendfromai = (aimessage: any) => {
    setMessages((prevMessages: any) => [
      ...prevMessages,
      { type: "ai", text: aimessage },
    ]);
  };
  const extractFrames = async (videoFile: any) => {
    setLoading(true);
    const [file] = videoFile;
    const fileUrl = URL.createObjectURL(file);
    const frames: any = await VideoToFrames.getFrames(
      fileUrl,
      5,
      VideoToFramesMethod.fps
    );

    setFrames(frames);
    setCurrentFrame(frames[0]);
    setLoading(false);
  };

  const handleFrameClick = (frame: any) => {
    if (currentIndex != frame) {
      setCurrentIndex(frame);
      // setMessages([]);
      // handleSendClick();
    }
  };
  const handleMediaClick = (mediaItem: any) => {
    if (currentIndex != mediaItem) {
      setCurrentIndex(mediaItem);
      // setExtractedData([]);
      // handleSendClick();
    }
  };
  const handleDeleteImage = (index: number) => {
    setMedia((prevMedia: any) =>
      prevMedia.filter((_: any, i: any) => i !== index)
    );
    if (index === 0) {
      if (media.length === 1) {
        console.log(media.length);
        handleDeleteAll();
      } else {
        setCurrentIndex(0);
      }
    } else {
      setCurrentIndex(index - 1);
    }
  };
  const handleDeleteAll = () => {
    setMedia([]);
    setSelectedMedia(null);
    setFrames([]);
    setCurrentFrame(null);
    setMediaType("");
    setCurrentIndex(0);
    setMessages([]);
  };

  const handleImageClick = () => {
    console.log(fileInputRef.current);
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
    console.log(fileInputRef.current);
  };

  const handlePrevClick = (mediaTypes: any) => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0
        ? (mediaTypes === "image" ? media.length : frames.length) - 1
        : prevIndex - 1
    );
  };
  useEffect(() => {
    if (media && media.length > 1 && !mediaMessages[currentIndex]) {
      setMessages([]);
      //enableAfter
      // handleSendClick();
    } else {
      // Display the stored message
      setMessages([{ type: "ai", text: mediaMessages[currentIndex] }]);
    }
    setMessages([{ type: "ai", text: mediaMessages[currentIndex] }]);
  }, [currentIndex, media]);
  useEffect(() => {
    // setExtractedData([]);
    if (!isRequestOngoing && messages) {
      messages.map((data: any, index: any) => {
        if (data && data.text && data.type === "ai") {
          const messageText = data.text.toString();
          const modified_string = messageText
            .replaceAll("```", "")
            .replaceAll("json", "")
            .replaceAll("default", "no data");
          console.log(modified_string);
          try {
            const parsedData = JSON.parse(modified_string);
            setExtractedData(parsedData);
            console.log(parsedData);
          } catch (error: any) {
            // message.error(error.toString());
          }
        }
      });
    }
  }, [messages, isRequestOngoing, currentIndex]);
  // useEffect(() => {
  //   if (media && media.length > 1) {
  //     setCurrentIndex(1);
  //     setCurrentIndex(0);
  //     message.info(currentIndex.toString());
  //   }
  // }, [media]);

  const handleNextClick = (mediaTypes: any) => {
    setCurrentIndex((prevIndex) =>
      prevIndex === (mediaTypes === "image" ? media.length : frames.length) - 1
        ? 0
        : prevIndex + 1
    );
  };
  const [croppedImage, setCroppedImage] = useState<Blob[]>([]);
  const imgRef = useRef<any>(null);

  const handleImageClickForCrop = (e: any) => {
    const imgElement = imgRef.current;
    const rect = imgElement.getBoundingClientRect();

    // Get click position relative to the image
    const cursorX = e.clientX - rect.left;
    const cursorY = e.clientY - rect.top;

    // Calculate crop dimensions based on natural size of image
    const naturalWidth = imgElement.naturalWidth;
    const naturalHeight = imgElement.naturalHeight;

    // Calculate scaling factor between displayed size and natural size
    const scaleX = naturalWidth / rect.width;
    const scaleY = naturalHeight / rect.height;

    // Adjust cursor position to match natural image size
    const adjustedX = cursorX * scaleX;
    const adjustedY = cursorY * scaleY;

    // Define a fixed crop width and height (e.g., 100x100 pixels)
    const cropWidth = naturalWidth / 3; // Fixed width of cropped area
    const cropHeight = naturalHeight - adjustedY; // Fixed height of cropped area

    // Calculate top-left corner of cropping box centered around click point
    const cropLeft = Math.max(0, adjustedX - cropWidth / 2);
    const cropTop = Math.max(0, adjustedY - cropHeight / 2);

    // Ensure cropping box does not exceed image boundaries
    const maxCropLeft = Math.min(cropLeft, naturalWidth - cropWidth);
    const maxCropTop = Math.min(cropTop, naturalHeight - cropHeight);

    // Crop the image using canvas
    cropImage(imgElement, maxCropLeft, adjustedY, cropWidth, cropHeight);
  };

  const cropImage = (
    imgElement: any,
    cropLeft: any,
    cropTop: any,
    cropWidth: any,
    cropHeight: any
  ) => {
    // Create a canvas element
    const canvas = document.createElement("canvas");
    canvas.width = cropWidth;
    canvas.height = cropHeight;

    const ctx: any = canvas.getContext("2d");

    // Draw the cropped portion of the image onto the canvas
    ctx.drawImage(
      imgElement,
      cropLeft,
      cropTop,
      cropWidth,
      cropHeight,
      0,
      0,
      canvas.width,
      canvas.height
    );

    // Convert canvas to an image URL and store it in state
    canvas.toBlob((blob) => {
      if (blob) {
        // downloadCroppedImage(blob, 0);
        setCroppedImage((prevCroppedImages: any) => [
          ...prevCroppedImages,
          blob,
        ]);
      }
    }, "image/png");

    // setCroppedImage(croppedImgUrl);
  };

  // const downloadCroppedImage = () => {
  //   if (croppedImage) {
  //     const link = document.createElement("a");
  //     link.href = croppedImage;
  //     link.download = "cropped_image.jpg";
  //     link.click();
  //   }
  // };
  useEffect(() => {
    if (croppedImage.length > 0) {
      handleSendClick();
    }
  }, [croppedImage]);
  const downloadCroppedImage = (blob: any, index: any) => {
    if (blob) {
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `cropped_image_${index + 1}.png`;
      link.click();
      URL.revokeObjectURL(url); // Clean up URL object after download
    }
  };
  const handleSendClick = async () => {
    if (chatInput.trim() !== "") {
      setMessages((prevMessages: any) => [
        ...prevMessages,
        { type: "user", text: chatInput },
      ]);
      if (!isRequestOngoing) {
        // Add a placeholder message to the state if no request is ongoing
        setMessages((prevMessages) => [
          ...prevMessages,
          { type: "ai", text: "Extracting the Informations" }, // Initial empty message for AI response
        ]);
      }
      const formData = new FormData();
      var imageData;
      if (mediaType === "image") {
        imageData = croppedImage[currentIndex];
      } else {
        imageData = dataURItoBlob(frames[currentIndex]);
      }
      formData.append("image", imageData);
      formData.append("prompt", chatInput);
      // setChatInput("");
      setIsRequestOngoing(true);
      try {
        const response: any = await fetch(APIStandards.APP.TRANSCRIBE, {
          method: "POST",
          body: formData,
        });

        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let result = "";

        while (true) {
          const { done, value } = await reader.read();

          result += decoder.decode(value, { stream: true });
          setMediaMessages((prev) => ({ ...prev, [currentIndex]: result }));
          setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages];
            updatedMessages[updatedMessages.length - 1].text = result;
            return updatedMessages;
          });
          if (done) break;
        }
      } catch (error) {
        console.error("Error during API call:", error);
      } finally {
        setIsRequestOngoing(false); // Reset the flag when the request is complete
      }
    }
  };
  return (
    <div className="flex flex-row h-[89vh]">
      <div className="w-[65%] h-full flex flex-col p-5 ">
        <div className="min-w-full max-w-full max-h-[60%] min-h-[60%]">
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleMediaSelect}
            multiple
            accept={
              mediaType === "image"
                ? "image/*"
                : mediaType === "video"
                ? "video/*"
                : "image/*,video/*"
            }
            style={{ display: "none" }}
          />
          {selectedMedia == null && (
            <>
              <div
                className="rounded-md h-[100%] bg-[#0A4378] flex items-center justify-center cursor-pointer"
                onClick={handleImageClick}
              >
                <div className="flex flex-col  text-white items-center justify-center">
                  <div>
                    <UploadCloudIcon color="white" height={200} width={200} />
                  </div>
                  <div className="text-2xl tracking-wider">
                    Click to upload images/videos
                  </div>
                </div>
              </div>
            </>
          )}
          {loading && (
            <div className="flex items-center justify-center mx-auto my-auto max-h-full min-h-full min-w-full max-w-full">
              <Hypnosis width="100px" height="100px" />
            </div>
          )}
          {selectedMedia && selectedMedia.type.startsWith("image") && (
            <div className="relative inline-block h-[100%] w-[100%] ">
              <Button
                variant="ghost"
                className="absolute h-[50px] w-[50px] left-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white rounded-full p-2 shadow-md hover:bg-opacity-70"
                disabled={currentIndex === 0}
                onClick={() => handlePrevClick("image")}
              >
                <ChevronLeftIcon />
              </Button>
              <img
                ref={imgRef}
                src={URL.createObjectURL(media[currentIndex])}
                alt="Selected Media"
                className="rounded-md max-h-full min-h-full min-w-full max-w-full object-contain"
                onClick={handleImageClickForCrop}
                style={{ cursor: "crosshair" }}
              />

              {/* {croppedImage && (
                <div>
                  <h3>Cropped Image Preview:</h3>
                  <img src={croppedImage} alt="Cropped" />
                  <button onClick={downloadCroppedImage}>
                    Download Cropped Image
                  </button>
                </div>
              )} */}
              <Button
                variant="ghost"
                className="absolute  h-[50px] w-[50px] right-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white rounded-full p-2 shadow-md hover:bg-opacity-70"
                disabled={currentIndex === media.length - 1}
                onClick={() => {
                  handleNextClick("image");
                }}
              >
                <ChevronRightIcon />
              </Button>
            </div>
          )}
          {selectedMedia &&
            selectedMedia.type.startsWith("video") &&
            currentFrame && (
              <div className="relative h-[100%] w-[100%] ">
                <Button
                  variant="ghost"
                  className="absolute h-[50px] w-[50px] left-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white rounded-full p-2 shadow-md hover:bg-opacity-70"
                  disabled={currentIndex === 0}
                  onClick={() => handlePrevClick("video")}
                >
                  <ChevronLeftIcon />
                </Button>
                <img
                  src={frames[currentIndex]}
                  alt="Selected Media"
                  className="rounded-md max-h-full min-h-full min-w-full max-w-full object-contain"
                />
                <Button
                  variant="ghost"
                  className="absolute  h-[50px] w-[50px] right-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white rounded-full p-2 shadow-md hover:bg-opacity-70"
                  disabled={currentIndex === frames.length - 1}
                  onClick={() => {
                    handleNextClick("video");
                  }}
                >
                  <ChevronRightIcon />
                </Button>
              </div>
            )}
        </div>
        {/* <div className="min-w-full max-w-full mt-10">
          <div className="relative ">
            <Textarea
              onChange={(e) => setChatInput(e.target.value)}
              placeholder="Chat with AI..."
              className="w-full rounded-md p-3 pr-12 resize-none"
              value={chatInput}
              rows={2}
            />
            <Button
              variant="ghost"
              size="icon"
              disabled={
                selectedMedia === null || chatInput === "" || isRequestOngoing
              }
              className="absolute top-1/2 right-3 -translate-y-1/2"
              onClick={handleSendClick}
            >
              <SendIcon className="w-5 h-5" />
            </Button>
          </div>
        </div> */}
        <div className="p-2 justify-end items-end ml-auto mt-28">
          <div className="flex flex-row gap-2 mt-5">
            {mediaType != "video" && (
              <div>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    //   fileInputRef.current.click();
                    handleImageClick();
                  }}
                  variant="ghost"
                  size="icon"
                  className=" w-10 h-10  bg-[#0A4378] text-white rounded-sm p-1 hover:bg-black"
                >
                  <PlusIcon color="white" className="w-5 h-5 " />
                </Button>
              </div>
            )}
            <div>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteAll();
                }}
                variant="ghost"
                size="icon"
                className=" w-10 h-10  bg-red-500 text-white rounded-sm p-1"
              >
                <Trash2Icon className="w-5 h-5" />
              </Button>
            </div>
          </div>
        </div>
        <div className="flex flex-row overflow-x-scroll min-w-full max-w-full max-h-[20%] min-h-[20%] mt-5">
          {media.length < 1 && frames.length < 1 && (
            <div className="relative min-w-[30%] max-w-[30%] max-h-full min-h-full pr-2 pb-2">
              <img
                src={placeholder}
                alt={`Media Placeholder`}
                className="rounded-md max-h-full min-h-full min-w-full max-w-full object-cover"
              />
            </div>
          )}
          {selectedMedia &&
            selectedMedia.type.startsWith("image") &&
            media.map((mediaItem: any, index: any) => (
              <div
                key={index}
                className="relative min-w-[30%] max-w-[30%] max-h-full min-h-full pr-2 pb-2"
                onClick={() => handleMediaClick(index)}
              >
                <img
                  src={URL.createObjectURL(mediaItem)}
                  alt={`Media ${index}`}
                  className="rounded-md max-h-full min-h-full min-w-full max-w-full object-cover"
                />
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteImage(index);
                  }}
                  variant="ghost"
                  size="icon"
                  className="absolute w-10 h-10 top-0 right-2 bg-red-500 text-white rounded-sm p-1"
                >
                  <Trash2Icon className="w-5 h-5" />
                </Button>
              </div>
            ))}
          {selectedMedia &&
            selectedMedia.type.startsWith("video") &&
            frames.map((frame, index) => (
              <div
                key={index}
                className="min-w-[30%] max-w-[30%] max-h-full min-h-full pr-2 pb-2"
                onClick={() => handleFrameClick(index)}
              >
                <img
                  src={frame}
                  alt={`Frame ${index}`}
                  className="rounded-md max-h-full min-h-full min-w-full max-w-full object-cover"
                />
              </div>
            ))}
        </div>
      </div>
      <div className="mt-5">
        {/* <Separator orientation="vertical" color="black" /> */}
      </div>
      <div className="w-[35%] h-[95%] mr-2 rounded flex flex-col  mt-5 shadow-sm border ">
        <header className="bg-[#0A4378] text-primary-foreground py-4 px-6 flex items-center justify-between rounded-t-md ">
          <div className="text-lg font-bold">Container Details</div>
          {/* <div className="flex items-center gap-4">
            <Button variant="ghost" size="icon">
              <BotIcon className="w-5 h-5" />
            </Button>
          </div> */}
        </header>
        <div className="flex-1 overflow-auto p-6">
          <div className="space-y-4">
            {messages.length < 1 && (
              <div className="flex h-[70vh] flex-col items-center justify-center">
                <div>
                  <Empty />
                </div>
              </div>
            )}
            {/* {messages.map((message: any, index: any) => {
              if (message.type === "user") {
                // return (
                //   <UserBubble key={index} text={message.text.toString()} />
                // );
              } else if (message.type === "ai") {
                // return <AIBubble key={index} text={message.text.toString()} />;
                return (
                  <div
                    className="text-xl items-center justify-center flex "
                    dangerouslySetInnerHTML={{ __html: message.text }}
                  />
                );
              }
              return null;
            })} */}
            {isRequestOngoing && !mediaMessages[currentIndex] && (
              <Descriptions
                bordered
                layout="horizontal"
                column={1}
                className="mt-4"
                items={[
                  {
                    key: "1",
                    label: "Container Id",

                    children: "extracting",
                  },
                  {
                    key: "2",
                    label: "Container Name",
                    children: "extracting",
                  },
                  {
                    key: "3",

                    label: "Container Number",
                    children: "extracting",
                  },
                  {
                    key: "4",

                    label: "Container Type",
                    children: "extracting",
                  },
                  {
                    key: "5",

                    label: "Dimensions",
                    children: "extracting",
                  },
                  {
                    key: "6",

                    label: "hazard Details",
                    children: "extracting",
                  },
                  {
                    key: "7",
                    label: "Rail Name",
                    children: "extracting",
                  },
                  {
                    key: "8",

                    label: "Manufacturer Number",
                    children: "extracting",
                  },
                ]}
              />
            )}
            {extractedData &&
              mediaMessages[currentIndex] &&
              extractedData.map((data: any, index: any) => (
                <Descriptions
                  bordered
                  layout="horizontal"
                  column={1}
                  className="mt-4"
                  items={[
                    // {
                    //   key: "1",
                    //   label: "Container Id",

                    //   children: data.container_Id || "extracting",
                    // },
                    // {
                    //   key: "2",
                    //   label: "Container Name",
                    //   children: data.container_name || "extracting",
                    // },
                    {
                      key: "3",

                      label: "Container Number",
                      children: data.container_number || "extracting",
                    },
                    {
                      key: "4",

                      label: "Container Type",
                      children: data.container_type || "extracting",
                    },
                    {
                      key: "5",

                      label: "Dimensions",
                      children: data.dimensions || "extracting",
                    },
                    {
                      key: "6",

                      label: "Hazard Details",
                      children: data.hazard_details || "extracting",
                    },
                    {
                      key: "7",
                      label: "Wagon Number",
                      children: data.wagon_number || "extracting",
                    },
                    // {
                    //   key: "8",

                    //   label: "Manufacturer Number",
                    //   children: data.manufacturer_name || "extracting",
                    // },
                  ]}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImportTab;
