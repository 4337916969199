import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, Title, Tooltip, Legend, PointElement } from 'chart.js';
import moment from 'moment';
import axios from 'axios';
import { Spin } from 'antd'; // Importing Spin component from Ant Design
import warningIcon from '../../../../../assets/warning.png';
// import { Footer } from '../../dashboard/components/footer';
import { APIStandards } from "../../../../../lib/api_standards";
import { Footer } from '../../dashboard/components/footer';
// Register necessary chart.js components
ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend);

const LineChartWithTimeFilter: React.FC = () => {
  const [timeRange, setTimeRange] = useState<string>('All Time');
  const [chartType, setChartType] = useState<string>('hourly'); // New state for chart type
  const [lineDatas, setLineDatas] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false); // State to track loading status

  // Fetch data dynamically based on the selected time range and chart type
  const fetchData = async (currentDate: string, type: string, charttyp: string) => {
    try {
      setLoading(true); // Set loading to true while fetching data

      let response;
      console.log("charttyp Response:", charttyp, type);
      if (type === "hourly") {
        response = await axios.get(
          `${APIStandards.APP.TEMP_STATS_HOURLY}/?Date=${currentDate}&type=${charttyp}`
        );
      } else if (type === "daily") {
        response = await axios.get(
          `${APIStandards.APP.TEMP_STATS_DAILY}/?Date=${currentDate}&type=${charttyp}`
        );
      } else if (type === "weekly") {
        response = await axios.get(
          `${APIStandards.APP.TEMP_STATS_WEEKLY}/?type=${charttyp}`
        );
      } else if (type === "monthly") {
        response = await axios.get(
          `${APIStandards.APP.TEMP_STATS_MONTHLY}/?type=${charttyp}`
        );
      }
      console.log("API Response:", response?.data);

      if (response?.data?.success === true && response?.data?.data?.length > 0) {
        setLineDatas(response.data.data);
      } else {
        setLineDatas([]); // Set to empty array if no data found
      }
    } catch (error) {
      console.error("Failed to fetch data, please try again!", error);
    } finally {
      setLoading(false); // Set loading to false once the data has been fetched or there is an error
    }
  };

  // Filter the date range dynamically
  const getStartAndEndDates = (range: string) => {
    const now = moment();
    let currentDate = now.format('YYYY-MM-DD');
    let charttyp = '';

    if (chartType === 'hourly') {
      switch (range) {
        case 'Last Hour':
          charttyp = 'Last Hour';
          break;
        case 'This Hour':
          charttyp = 'This Hour';
          break;
        case 'Last 12 Hours':
          charttyp = 'Last 12 Hours';
          break;
        case 'All Time':
        default:
          charttyp = 'All Time';
          break;
      }
    } else if (chartType === 'daily') {
      switch (range) {
        case 'Today':
          charttyp = 'Today';
          break;
        case 'Yesterday':
          charttyp = 'Yesterday';
          break;
        case 'Last 7 Days':
          charttyp = 'Last 7 Days';
          break;
        case 'Last Month':
          charttyp = 'Last Month';
          break;
        case 'All Time':
        default:
          charttyp = 'All Time';
          break;
      }
    } else if (chartType === 'weekly') {
      switch (range) {
        case 'This Week':
          charttyp = 'This Week';
          break;
        case 'Last Week':
          charttyp = 'Last Week';
          break;
        case 'All Time':
        default:
          charttyp = 'All Time';
          break;
      }
    } else if (chartType === 'monthly') {
      switch (range) {
        case 'This Year':
          charttyp = 'This Year';
          break;
        case 'Last Year':
          charttyp = 'Last Year';
          break;
        case 'All Time':
        default:
          charttyp = 'All Time';
          break;
      }
    }
    return { currentDate, charttyp };
  };

  // Update data whenever the time range or chart type changes
  useEffect(() => {
    const { currentDate, charttyp } = getStartAndEndDates(timeRange);
    fetchData(currentDate, chartType, charttyp);
  }, [timeRange, chartType]);

  // Prepare data for the chart based on the chart type
  const getChartData = () => {
    const labels = lineDatas.map(item => moment(chartType === 'hourly' ? item.hour : chartType === 'daily' ? item.date : item.month).format(chartType === 'hourly' ? 'HH' : chartType === 'daily' ? 'MMM Do' : 'MMM'));

    return {
      temperature: {
        labels,
        datasets: [
          {
            label: 'Camera Temperature',
            data: lineDatas.map(item => item.camera_temperature),
            fill: false,
            borderColor: 'rgba(75, 192, 192, 1)',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            tension: 0.4,
            borderWidth: 2,
          },
          {
            label: 'Vision Box Temperature',
            data: lineDatas.map(item => item.vision_temperature),
            fill: false,
            borderColor: 'rgba(102, 102, 255, 1)',
            backgroundColor: 'rgba(102, 102, 255, 0.2)',
            tension: 0.4,
            borderWidth: 2,
          },
        ],
      },
      humidity: {
        labels,
        datasets: [
          {
            label: 'Camera Humidity',
            data: lineDatas.map(item => item.camera_humidity),
            fill: false,
            borderColor: 'rgba(153, 102, 255, 1)',
            backgroundColor: 'rgba(153, 102, 255, 0.2)',
            tension: 0.4,
            borderWidth: 2,
          },
          {
            label: 'Vision Box Humidity',
            data: lineDatas.map(item => item.vision_humidity),
            fill: false,
            borderColor: 'rgba(0, 255, 153, 1)',
            backgroundColor: 'rgba(0, 255, 153, 0.2)',
            tension: 0.4,
            borderWidth: 2,
          },
        ],
      }
    };
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      title: {
        display: false,
        text: `Line Chart - ${timeRange} (${chartType})`,
      },
      legend: {
        position: 'top' as const,
      },
    },
    scales: {
      x: {
        type: 'category' as const,
        categoryPercentage: 0.8,
        barPercentage: 0.4,
        grid: {
          display: false, // Remove the grid lines on the X axis
        },
        ticks: {
          display: false, // Keep the ticks visible (optional)
          color: '#555', // Adjust tick color if needed
        },
        borderColor: 'rgba(0, 0, 0, 0)', // Remove X axis border
      },
      y: {
        type: 'linear' as const,
        beginAtZero: true,
        grid: {
          display: false, // Remove grid lines on the Y axis
        },
        ticks: {
          display: false, // Keep Y ticks visible
          color: '#555', // Adjust tick color if needed
        },
        borderColor: 'rgba(0, 0, 0, 0)', // Remove Y axis border
      },
    },
  };

  const { temperature, humidity } = getChartData();

  return (
    <div>
      {/* Display Loading Indicator */}
      {loading ? (
        <div className = 'overflow-y-hidden'
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh', // Full viewport height
            flexDirection: 'column', // Stack items vertically
            textAlign: 'center',
          }}
        >
          <Spin size="large" style={{ marginBottom: '20px' }} />
          <div className="font-bold text-xl text-gray-500 p-2">
            Loading...
          </div>
        </div>
      ) : (
        <>
          {/* Only show the select fields when data is loaded */}
          <div style={{ marginTop: '25px', marginLeft: '20px' }}>
            <label htmlFor="time-range" style={{ marginRight: '10px', marginTop: '20px' }}>
              Select Time Range:
            </label>
            <select
              id="time-range"
              value={timeRange}
              onChange={(e) => setTimeRange(e.target.value)}
              style={{ padding: '12px', fontSize: '15px' }}
            >
              {chartType === 'hourly' ? (
                <>
                  <option value="This Hour">This Hour</option>
                  <option value="Last Hour">Last Hour</option>
                  <option value="Last 12 Hours">Last 12 Hours</option>
                  <option value="All Time">All Time</option>
                </>
              ) : chartType === 'daily' ? (
                <>
                  <option value="Today">Today</option>
                  <option value="Yesterday">Yesterday</option>
                  <option value="Last 7 Days">Last 7 Days</option>
                  <option value="Last Month">Last Month</option>
                  <option value="All Time">All Time</option>
                </>
              ) : chartType === 'weekly' ? (
                <>
                  <option value="This Week">This Week</option>
                  <option value="Last Week">Last Week</option>
                  <option value="Last 4 Weeks">Last 4 Weeks</option>
                  <option value="All Time">All Time</option>
                </>
              ) : chartType === 'monthly' ? (
                <>
                  <option value="This Year">This Year</option>
                  <option value="Last Year">Last Year</option>
                  <option value="All Time">All Time</option>
                </>
              ) : null}
            </select>

            <label htmlFor="chart-type" style={{ marginLeft: '20px', marginRight: '10px', marginTop: '20px' }}>
              Select Chart Type:
            </label>
            <select
              id="chart-type"
              value={chartType}
              onChange={(e) => setChartType(e.target.value)}
              style={{ padding: '10px', fontSize: '16px' }}
            >
              <option value="hourly">Hourly</option>
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
            </select>
          </div>

          {/* Display "No Data Available" with warning icon */}
          {lineDatas.length === 0 ? (
  <div 
    className="text-center mt-20"
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '90vh', // Adjust the height to reduce space between the content and the top
      marginTop: '20px', // Adjust the marginTop to lift it up a bit
    }}
  >
    {/* Warning Icon above the text */}
    <img 
      src={warningIcon} 
      alt="Warning" 
      style={{
        width: '50px', 
        marginBottom: '10px', 
      }} 
    />
    <h3 style={{ fontSize: '24px', color: 'blue', fontWeight: 'bold' }}>
      No Data Available
    </h3>
  </div>
) : (
  <div style={{ width: '50%', margin: '10px auto' }}>
    <div style={{ textAlign: 'center', marginBottom: '30px' }}>
      <h2
        style={{
          textAlign: 'center',
          fontWeight: 'bold',
          color: '#bf40bf',
          fontSize: '20px',
          textTransform: 'uppercase',
          letterSpacing: '2px',
          marginBottom: '15px',
          textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
        }}
      >
        Temperature Data
      </h2>
    </div>
    <Line data={temperature} options={chartOptions} />

    <div style={{ textAlign: 'center', marginTop: '30px' }}>
      <h2
        style={{
          textAlign: 'center',
          fontWeight: 'bold',
          color: '#6600cc',
          fontSize: '20px',
          textTransform: 'uppercase',
          letterSpacing: '2px',
          marginBottom: '15px',
          textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
        }}
      >
        Humidity Data
      </h2>
    </div>
    <Line data={humidity} options={chartOptions} />
  </div>
          )}
        </>
      )}

    {/* Footer with copyright */}
    {/* <footer
      style={{
        textAlign: 'center',
        padding: '10px',
        marginTop: '150px',
        backgroundColor: '#ffffff',
        color: 'gray-300',
        position: 'relative',
        bottom: '0',
        width: '100%',
        fontSize: '14px',
      }}
    >
      <p>Copyrights &copy; {new Date().getFullYear()} RoshAi. All Rights Reserved.</p>
    </footer> */}
          <Footer/>

    </div>
  );
};

export default LineChartWithTimeFilter;
