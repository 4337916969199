import React, { useEffect, useState } from "react";
import { Button } from "../../../../../components/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../../../components/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../components/tabs";
import { BarGraph } from "../components/graphs/bargraph";
import { AreaGraph } from "../components/graphs/areagraph";
import { PieGraph } from "../components/graphs/piegraph";
import axios from "axios";
import {
  ContainerIcon,
  LucideTrendingDown,
  TrainIcon,
  User2Icon,
} from "lucide-react";
import { APIStandards } from "../../../../../lib/api_standards";

type Props = {};

function DashboardScreen({}: Props) {
  const [userDetails, setUserDetails] = useState(
    JSON.parse(sessionStorage.getItem("activeUser") || "{}")
  );
  const userName = userDetails.userName;
  const [usersCount, setusersCount] = useState<any>(0);
  const [instancesCount, setinstancesCount] = useState<any>(0);
  const [containersCount, setcontainersCount] = useState<any>(0);
  const [containersCountToday, setcontainersCountToday] = useState<any>(0);
  useEffect(() => {
    (async () => {
      const res = await axios.get(APIStandards.APP.GET_COUNTS);
      const data = res.data;
      setusersCount(data.users_count);

      setinstancesCount(data.instances_count);

      setcontainersCount(data.containers_count);

      setcontainersCountToday(data.containers_count_today);
    })();
  }, []);
  return (
    <div className="p-5 max-h-[100vh] min-h-[100vh] overflow-y-scroll">
      <div className="space-y-2">
        <div className="flex items-center justify-between space-y-2">
          <h2 className="text-2xl font-bold tracking-tight">
            Hi,{" "}
            {userName.toString()[0].toUpperCase() +
              userName.toString().substring(1)}{" "}
            👋
          </h2>
          {/* <div className="hidden items-center space-x-2 md:flex">
            <CalendarDateRangePicker />
            <Button>Download</Button>
          </div> */}
        </div>
        <Tabs defaultValue="overview" className="space-y-4">
          <TabsList>
            <TabsTrigger value="overview">Overview</TabsTrigger>
            <TabsTrigger value="analytics" disabled>
              Analytics
            </TabsTrigger>
          </TabsList>
          <TabsContent value="overview" className="space-y-4">
            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
              <Card>
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                  <CardTitle className="text-sm font-medium">
                    Total Instances
                  </CardTitle>
                  <TrainIcon className="text-muted-foreground" />
                </CardHeader>
                <CardContent>
                  <div className="text-2xl font-bold">{instancesCount}</div>
                </CardContent>
              </Card>
              <Card>
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                  <CardTitle className="text-sm font-medium">
                    Total Users
                  </CardTitle>
                  <User2Icon className="text-muted-foreground" />
                </CardHeader>
                <CardContent>
                  <div className="text-2xl font-bold">{usersCount}</div>
                </CardContent>
              </Card>
              <Card>
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                  <CardTitle className="text-sm font-medium">
                    Total Containers
                  </CardTitle>
                  <ContainerIcon className="text-muted-foreground" />
                </CardHeader>
                <CardContent>
                  <div className="text-2xl font-bold">{containersCount}</div>
                </CardContent>
              </Card>
              <Card>
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                  <CardTitle className="text-sm font-medium">
                    Containers scanned today
                  </CardTitle>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="h-4 w-4 text-muted-foreground"
                  >
                    <path d="M22 12h-4l-3 9L9 3l-3 9H2" />
                  </svg>
                </CardHeader>
                <CardContent>
                  <div className="text-2xl font-bold">
                    {containersCountToday}
                  </div>
                </CardContent>
              </Card>
            </div>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-7">
              <div className="col-span-4">
                <BarGraph />
              </div>
              <div className="col-span-4 md:col-span-3">
                <AreaGraph />
              </div>
              {/* <Card className="col-span-4 md:col-span-3">
                <CardHeader>
                  <CardTitle>Collected Data</CardTitle>
                  <CardDescription>September - October 2024</CardDescription>
                </CardHeader> 
                <CardContent>
                  <AreaGraph />
                </CardContent>
              </Card> */}
              {/* <div className="col-span-4">
                <AreaGraph />
              </div> */}
              {/* <div className="col-span-4 md:col-span-3">
                <PieGraph />
              </div> */}
            </div>
          </TabsContent>
        </Tabs>
      </div>
      <footer
      style={{
        textAlign: 'center',
        padding: '10px',
        marginTop: '130px',
        backgroundColor: '#ffffff',
        color: 'gray-300',
        position: 'relative',
        bottom: '0',
        width: '100%',
        fontSize: '14px',
      }}
    >
      <p>Copyrights &copy; {new Date().getFullYear()} RoshAi. All Rights Reserved.</p>
    </footer>
     
    </div>
  );
}
export default DashboardScreen;
