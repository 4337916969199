import React, { useState, useEffect } from 'react';
import {
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  Legend,
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import axios from 'axios';
import { ChevronsRight, ChevronsLeft } from 'lucide-react';
import moment from 'moment';
import { Spin } from 'antd';  // Ant Design Spinner
import warningIcon from '../../../../../assets/warning.png';
// import { Footer } from '../../dashboard/components/footer';
import { APIStandards } from "../../../../../lib/api_standards";
import { Footer } from '../../dashboard/components/footer';
type FrameMessageKey = 'x' | 'y' | 'z';

interface FrameMessage {
  'Frame Number': string;
  'Detected Objects': string;
  x: string;
  y: string;
  z: string;
  Velocity: string;
  time: string;
}

interface FrameData {
  [key: string]: {
    'Frame Number': string;
    'Detected Objects': string;
    x: string;
    y: string;
    z: string;
    Velocity: string;
    time: string;
  };
}

type Frames = FrameData[];

const UserProjectDashboard = () => {
  const [frames, setFrames] = useState<Frames>([]);
  const [currentFrameIndex, setCurrentFrameIndex] = useState(0);
  const [currentFrameData, setCurrentFrameData] = useState<FrameData | null>(null);
  const [isLoading, setIsLoading] = useState(true); // Loading state

  const now = moment();
  let currentDate = now.format('YYYY-MM-DD');
  let currentDateTime = now.format('HH');

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true); // Set loading to true when starting to fetch

        const response = await axios.get(
          `${APIStandards.APP.GET_RADAR_DATA}/?date=${currentDate}&time=${currentDateTime}`
        );        
        if (response.data.success === true) {
          setFrames(response.data.data);
        } else {
          console.error("Error:", response.data.message);
        }
      } catch (error) {
        console.error("Failed to fetch data, please try again!", error);
      } finally {
        setIsLoading(false); // Set loading to false after data fetch is complete
      }
    };

    fetchData();
  }, [currentDate, currentDateTime]);

  useEffect(() => {
    if (frames.length > 0) {
      setCurrentFrameData(frames[currentFrameIndex]);
    }
  }, [frames, currentFrameIndex]);

  const hasValidData = currentFrameData !== null;

  // Data processing logic
  const processedData = hasValidData
    ? [
        {
          subject: 'x object',
          'Object 0': parseFloat(currentFrameData[0].x),
          // Other data processing...
        },
        // Other axes...
      ]
    : [];

  const processedScatterData = hasValidData
    ? [
        { name: 'Object 0', x: parseFloat(currentFrameData[0].x), y: parseFloat(currentFrameData[0].y) },
        // Other scatter points...
      ]
    : [];

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const handleNextFrame = () => {
    setCurrentFrameIndex((prevIndex) => (prevIndex + 1 < frames.length ? prevIndex + 1 : 0));
  };

  const handlePreviousFrame = () => {
    setCurrentFrameIndex((prevIndex) => (prevIndex - 1 >= 0 ? prevIndex - 1 : frames.length - 1));
  };

  return (
    <div className="p-2 space-y-10 mt-15 relative">
      {/* Loading State: Show loading spinner until data is loaded */}
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',  // Full viewport height
            flexDirection: 'column',  // Stack items vertically
            textAlign: 'center',
          }}
        >
         <Spin size="large" style={{ marginBottom: '20px' }} />
    <div className="font-bold text-xl text-gray-500 p-2">
      Loading...
    </div>
  </div>
      ) : (
        <>
          <div style={{ width: '50%', margin: '10px auto' }}>
            <div style={{ textAlign: 'center', marginBottom: '30px', marginLeft: '-17%' }}>
              {/* <h2
                style={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  color: '#9966ff', // Purple-red color for Radar Data
                  fontSize: '40px',
                  textTransform: 'uppercase',
                  letterSpacing: '2px',
                  marginBottom: '15px',
                  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
                }}
              >
                Radar Data
              </h2> */}
            </div>
          </div>

          {/* Conditional Rendering: If no data is available, show "No Data Available" */}
          {!hasValidData ? (
         <div 
         className="text-center mt-20"
         style={{
           display: 'flex',
           flexDirection: 'column',
           justifyContent: 'center',
           alignItems: 'center',
           height: '90vh', // Adjust the height to reduce space between the content and the top
           marginTop: '20px', // Adjust the marginTop to lift it up a bit
         }}
       >
         {/* Warning Icon above the text */}
         <img 
           src={warningIcon} 
           alt="Warning" 
           style={{
             width: '50px', 
             marginBottom: '10px', 
           }} 
         />
         <h3 style={{ fontSize: '24px', color: 'blue', fontWeight: 'bold' }}>
           No Data Available
         </h3>
       </div>
          ) : (
            <>
              <div className="flex gap-7" style={{ marginLeft: '1200px' }}>
                <div className="flex-1 relative" style={{ height: '60vh' }}>
                  {/* Semi-circle rendering */}
                  <div
                    className="absolute flex bg-gray-100 rounded-t-full"
                    style={{
                      width: '350px',
                      height: '200px',
                      transform: 'translateY(0)',
                      clipPath: 'ellipse(100% 100% at 50% 100%)',
                    }}
                  />

                  <ResponsiveContainer width="85%" height="20%" className={""}>
                    <ScatterChart className="mt-28 mr-16">
                      <CartesianGrid horizontal={false} vertical={false} strokeDasharray="3 3" />
                      <XAxis type="number" dataKey="x" name="X Axis" unit="m" domain={[-3, 3]} hide={true} axisLine={false} tickLine={false} tick={{ transform: 'translateY(5px)' }} />
                      <YAxis type="number" dataKey="y" name="Y Axis" unit="m" domain={[-3, 3]} hide={true} />
                      <Tooltip />
                      {processedScatterData.map((dataPoint, index) => {
                        const color = getRandomColor();
                        return <Scatter key={index} name={dataPoint.name} data={[dataPoint]} fill={color} line={false} />;
                      })}
                    </ScatterChart>
                  </ResponsiveContainer>
                </div>
              </div>

              {/* Radar chart */}
              <div className="absolute z-10" style={{ top: '220px', right: '500px' }}>
                <RadarChart width={800} height={800} outerRadius={400} data={processedData}>
                  <PolarGrid />
                  <PolarAngleAxis dataKey="subject" />
                  <PolarRadiusAxis angle={30} domain={[-10, 10]} />

                  {Object.keys(currentFrameData || {}).map((key, index) => {
                    const color = getRandomColor();
                    return (
                      <Radar
                        key={index}
                        name={`Object ${index}`}
                        dataKey={`Object ${index}`}
                        stroke={color}
                        fill={color}
                        fillOpacity={0.3 + index * 0.2}
                      />
                    );
                  })}
                  <Legend
                    layout="horizontal"
                    align="center"
                    verticalAlign="bottom"
                    iconType="circle"
                    wrapperStyle={{ paddingTop: '20px' }}
                  />
                </RadarChart>
              </div>

              {/* Navigation Buttons: Only show after data is loaded */}
              <div className="fixed bottom-10 right-10 flex space-x-5 z-50">
                <button
                  className="text-xl p-2 border-2 border-gray-300 rounded-md hover:bg-gray-200"
                  onClick={handlePreviousFrame}
                >
                  <ChevronsLeft className="text-blue-800 hover:text-blue-600" />
                </button>

                <button
                  className="text-xl p-2 border-2 border-gray-300 rounded-md hover:bg-gray-200"
                  onClick={handleNextFrame}
                >
                  <ChevronsRight className="text-blue-800 hover:text-blue-600" />
                </button>
              </div>
            </>
          )}
        </>
      )}
         {/* Footer with copyright */}
    {/* <footer
      style={{
        textAlign: 'center',
        padding: '10px',
        marginTop: '197px',
        backgroundColor: '#ffffff',
        color: 'gray-300',
        position: 'relative',
        bottom: '0',
        width: '100%',
        fontSize: '14px',
      }}
    >
      <p>Copyrights &copy; {new Date().getFullYear()} RoshAi. All Rights Reserved.</p>
    </footer> */}
              <Footer/>

    </div>
  );
};

export default UserProjectDashboard;

