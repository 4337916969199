import { Badge, Typography } from "antd";
import {
  BarChart,
  Book,
  Calendar,
  CameraIcon,
  ChevronLeft,
  ChevronRight,
  ContainerIcon,
  DatabaseIcon,
  DiamondIcon,
  MapIcon,
  PanelsTopBottomIcon,
  ShoppingCart,
  TestTubeIcon,
  TestTubesIcon,
  User2Icon,
  WorkflowIcon,
  FileChartColumn,
  ChartNoAxesColumnIncreasing,
  ChartNetwork,
  ChartScatter,
  LogOutIcon,
} from "lucide-react";
import React from "react";
import {
  Menu,
  menuClasses,
  MenuItem,
  MenuItemStyles,
  Sidebar,
  SubMenu,
} from "react-pro-sidebar";
import roshailogo from "../../../../../assets/rosh-ai-logo-white.png";
import { Button } from "../../../../../components/button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import RadarData from "../../data-visualization/pages/RadarData";
import Ambient from "../../data-visualization/pages/Ambient";

type Theme = "light" | "dark";

const themes = {
  light: {
    sidebar: {
      backgroundColor: "#ffffff",
      color: "#607489",
    },
    menu: {
      menuContent: "#fbfcfd",
      icon: "#0098e5",
      hover: {
        backgroundColor: "#c5e4ff",
        color: "#44596e",
      },
      disabled: {
        color: "#9fb6cf",
      },
    },
  },
  dark: {
    sidebar: {
      backgroundColor: "#0b2948",
      color: "#8ba1b7",
    },
    menu: {
      menuContent: "#082440",
      icon: "#59d0ff",
      hover: {
        backgroundColor: "#00458b",
        color: "#b6c8d9",
      },
      disabled: {
        color: "#3e5e7e",
      },
    },
  },
};

// hex to rgba converter
const hexToRgba = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const SideBar: React.FC = () => {
  const [collapsed, setCollapsed] = React.useState(false);
  const [toggled, setToggled] = React.useState(false);
  const [broken, setBroken] = React.useState(false);
  const [rtl, setRtl] = React.useState(false);
  const [hasImage, setHasImage] = React.useState(false);
  const [theme, setTheme] = React.useState<Theme>("dark");
  const navigate = useNavigate();

  // handle on RTL change event
  const handleRTLChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRtl(e.target.checked);
  };

  // handle on theme change event
  const handleThemeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTheme(e.target.checked ? "dark" : "light");
  };

  // handle on image change event
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasImage(e.target.checked);
  };
  const pathname = useLocation();

  const menuItemStyles: MenuItemStyles = {
    root: {
      fontSize: "13px",
      fontWeight: 400,
    },
    icon: {
      color: themes[theme].menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0
          ? hexToRgba(
              themes[theme].menu.menuContent,
              hasImage && !collapsed ? 0.4 : 1
            )
          : "transparent",
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
      "&:hover": {
        backgroundColor: hexToRgba(
          themes[theme].menu.hover.backgroundColor,
          hasImage ? 0.8 : 1
        ),
        color: themes[theme].menu.hover.color,
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  return (
    <div
      className="max-h-[100vh] min-h-[100vh]"
      style={{
        display: "flex",

        direction: rtl ? "rtl" : "ltr",
      }}
    >
      <Sidebar
        collapsed={collapsed}
        toggled={toggled}
        onBackdropClick={() => setToggled(false)}
        onBreakPoint={setBroken}
        image="https://user-images.githubusercontent.com/25878302/144499035-2911184c-76d3-4611-86e7-bc4e8ff84ff5.jpg"
        rtl={rtl}
        breakPoint="md"
        backgroundColor={hexToRgba(
          themes[theme].sidebar.backgroundColor,
          hasImage ? 0.9 : 1
        )}
        rootStyles={{
          color: themes[theme].sidebar.color,
        }}
      >
        <div
          className={`absolute  bottom-1 opacity-[0.7] text-black ${
            collapsed ? "left-0 right-0 mx-auto w-[10px] " : "right-1"
          }  `}
        >
          <div className="flex flex-col gap-2">
            <div
              className={`${
                collapsed ? "mr-2" : "flex items-end justify-end mr-2"
              }`}
            >
              <Button
                variant={"outline"}
                size={"icon"}
                onClick={() => {
                  sessionStorage.removeItem("activeUser");
                  navigate("/login");
                }}
              >
                <LogOutIcon />
              </Button>
            </div>
            <div>
              {" "}
              <div className="flex flex-row items-center gap-2 mr-2">
                {!collapsed && (
                  <div>
                    <img
                      onClick={() => {
                        navigate("/login");
                      }}
                      src={roshailogo}
                      alt="Logo"
                      className={` items-center justify-center my-auto  flex mx-auto cursor-pointer ${
                        collapsed ? "h-[30px]" : "h-[70px]"
                      }`}
                    />
                  </div>
                )}
                <div>
                  <Button
                    variant={"outline"}
                    size={"icon"}
                    onClick={() => setCollapsed(!collapsed)}
                  >
                    {collapsed ? <ChevronRight /> : <ChevronLeft />}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <div style={{ marginBottom: "24px", marginTop: "16px" }}>
            <img
              onClick={() => {
                //   navigate("/");
              }}
              src={roshailogo}
              alt="Logo"
              className={` items-center justify-center my-auto  flex mx-auto cursor-pointer ${
                collapsed ? "h-[30px]" : "h-[70px]"
              }`}
            />
          </div> */}
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          {!collapsed && (
            <div className="flex items-center mx-auto mt-5">
              <Badge color="gold" count={"Pro"}>
                <div
                  className=" font-bold text-xl text-white p-2"
                  onClick={() => navigate("/login")}
                >
                  Container Vision
                </div>
              </Badge>
            </div>
          )}
          <div style={{ flex: 1, marginBottom: "32px" }}>
            <div
              style={{ padding: "0 24px", marginBottom: "8px" }}
              className="mt-10"
            >
              <div
                style={{ opacity: collapsed ? 0 : 0.7, letterSpacing: "0.5px" }}
              >
                {/* General */}
              </div>
            </div>
            <Menu menuItemStyles={menuItemStyles}>
              <MenuItem
                className={`${
                  pathname.pathname === "/admin"
                    ? " bg-blue-500/30 text-white"
                    : ""
                }`}
                active={true}
                component={<Link to="/admin" />}
                icon={<PanelsTopBottomIcon />}
              >
                Dashboard
              </MenuItem>
              <MenuItem
                className={`${
                  pathname.pathname === "/admin/users"
                    ? " bg-blue-500/30 text-white"
                    : ""
                }`}
                component={<Link to="/admin/users" />}
                icon={<User2Icon />}
              >
                Users
              </MenuItem>
              <MenuItem
                className={`${
                  pathname.pathname === "/admin/instances"
                    ? " bg-blue-500/30 text-white"
                    : ""
                }`}
                component={<Link to="/admin/instances" />}
                icon={<ContainerIcon />}
              >
                Instances
              </MenuItem>
              <MenuItem
                className={`${
                  pathname.pathname === "/admin/testing"
                    ? " bg-blue-500/30 text-white"
                    : ""
                }`}
                component={<Link to="/admin/testing" />}
                icon={<TestTubeIcon />}
              >
                Quick Test
              </MenuItem>

              <SubMenu
                label="Data Visualization"
                icon={<FileChartColumn />}
                className={`${
                  pathname.pathname.startsWith("/admin/data-visualizing") // Adjust to check for any of the sub-paths
                    ? " bg-blue-500/30 text-white"
                    : ""
                }`}
              >
                <MenuItem
                  component={<Link to="/admin/data-visualizing/radar" />} // Adjust link as needed
                  className={`${
                    pathname.pathname === "/admin/data-visualizing/radar"
                      ? " bg-blue-500/30 text-white"
                      : ""
                  }`}
                  icon={<ChartScatter />}
                >
                  Radar Data
                </MenuItem>
                <MenuItem
                  component={<Link to="/admin/data-visualizing/ambient" />} // Adjust link as needed
                  className={`${
                    pathname.pathname === "/admin/data-visualizing/ambient"
                      ? " bg-blue-500/30 text-white"
                      : ""
                  }`}
                  icon={<ChartNoAxesColumnIncreasing />}
                >
                  Ambient
                </MenuItem>
              </SubMenu>
              <MenuItem
                className={`${
                  pathname.pathname === "/admin/collected-data"
                    ? " bg-blue-500/30 text-white"
                    : ""
                }`}
                component={<Link to="/admin/collected-data" />}
                icon={<DatabaseIcon />}
              >
                Collected Data
              </MenuItem>
              {/* <MenuItem
                className={`${
                  pathname.pathname === "/admin/data-collection"
                    ? " bg-blue-500/30 text-white"
                    : ""
                }`}
                component={<Link to="/admin/data-collection" />}
                icon={<CameraIcon />}
              >
                Data Collection
              </MenuItem> */}
            </Menu>
            {/* 
            <div
              style={{
                padding: "0 24px",
                marginBottom: "8px",
                marginTop: "32px",
              }}
            >
              <div
                style={{ opacity: collapsed ? 0 : 0.7, letterSpacing: "0.5px" }}
              >
                Extra
              </div>
            </div>

            <Menu menuItemStyles={menuItemStyles}>
              <MenuItem
                icon={<Calendar />}
                suffix={<Badge color="green" count={"New"} />}
              >
                Calendar
              </MenuItem>
              <MenuItem icon={<Book />}>Documentation</MenuItem>
              <MenuItem disabled icon={<WorkflowIcon />}>
                Examples
              </MenuItem>
            </Menu> */}
          </div>
          {/* <SidebarFooter collapsed={collapsed} /> */}
        </div>
      </Sidebar>
    </div>
  );
};
