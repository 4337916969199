// import "./App.css";

import { CameraIcon, ImageIcon, ImportIcon } from "lucide-react";

import { Tabs } from "antd";
import ImportTab from "../components/tabs/importTab";
import LiveTab from "../components/tabs/liveTab";
import { useLocation } from "react-router-dom";
import CollectedDataImages from "../components/tabs/collected_data_images";
// const ffmpeg: any = new FFmpeg();
function InstanceDetails() {
  const pathname = useLocation();
  var tabs = pathname.pathname.startsWith("/admin/manage-data")
    ? [
        { icon: <CameraIcon />, name: "TopCam" },
        { icon: <CameraIcon />, name: "BotCam" },
      ]
    : [
        { icon: <CameraIcon />, name: "Live" },
        // { icon: <ImportIcon />, name: "Import" },
      ];

  const getTabs = (name: any) => {
    if (pathname.pathname.startsWith("/admin/manage-data")) {
      if (name === "TopCam") {
        return <CollectedDataImages type_no={1} />;
      }
      if (name === "BotCam") {
        return <CollectedDataImages type_no={2} />;
      }
    } else {
      if (name == "Live") {
        return <LiveTab />;
      }
    }

    // if (name == "Import") {
    //   return <ImportTab />;
    // }
  };
  return (
    <div className="w-[99%] h-[85%] flex flex-col ">
      <div className="mt-2">
        <Tabs
          defaultActiveKey="1"
          type="card"
          items={tabs.map((item, i) => {
            const id = String(i + 1);
            return {
              label: (
                <div className="flex flex-row items-center justify-center gap-2">
                  <div>{item.icon}</div>
                  <div>{item.name}</div>
                </div>
                // <span>
                //   {item.icon} {item.name}
                // </span>
              ),
              key: item.name,
              children: getTabs(item.name),
            };
          })}
        />
      </div>
    </div>
  );
}

export default InstanceDetails;
