const isDev = false
const devUrl = "http://localhost:8000"
const prodUrl = "https://roshai-coai-cvpro.azurewebsites.net"
const socketUrl ="ws://localhost:5678"
const  serverUrl = isDev?devUrl:prodUrl
export const APIStandards ={
    ADMIN:{
        CREATE_USER:`${serverUrl}/api/container_vision/create_user/`,
        LIST_USER:`${serverUrl}/api/container_vision/list_users/`,
        
    },
    USER:{
        SOCKET_URL:socketUrl,
        LOGIN:`${serverUrl}/api/container_vision/login/`
    },
    APP:{
        GET_COUNTS:`${serverUrl}/api/container_vision/get_counts/?date=all`,
        GET_INSTANCES:`${serverUrl}/api/container_vision/instance/?date=all`,
        TEMP_STATS_HOURLY:`${serverUrl}/api/container_vision/temperature-stats-hourly`,
        TEMP_STATS_DAILY:`${serverUrl}/api/container_vision/temperature-stats`,
        TEMP_STATS_WEEKLY:`${serverUrl}/api/container_vision/temperature-stats-weekly`,
        TEMP_STATS_MONTHLY:`${serverUrl}/api/container_vision/temperature-stats-monthly`,
        GET_RADAR_DATA:`${serverUrl}/api/container_vision/get_radar_data`,
        LIST_COLLECTED_IMAGES:`${serverUrl}/api/container_vision/list_collected_images`,
        TRANSCRIBE:`${serverUrl}/api/container_vision/transcribe/`,
        GET_PREDICTION:`${serverUrl}/api/container_vision/prediction`
    }
}
