import { useEffect, useState } from "react";

import axios from "axios";
import { DataTable } from "../components/table/data-table";
import { columns } from "../components/table/columns";
import { Button, Spin } from "antd";
import { useLocation } from "react-router-dom";
import { RefreshCcw } from "lucide-react";
import { APIStandards } from "../../../../../lib/api_standards";

// async function getData(): Promise<Instance[]> {

//   // Fetch data from your API here.
//   return InstanceList;
// }

// export default async function InstanceList2() {
// const data = await getData();

// return (
//   <div className="container mx-auto py-10">
//     <DataTable columns={columns} data={data} />
//   </div>
// );
// }

function DataCollectionList() {
  const [InstanceList, setInstanceList] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const pathname = useLocation();
  const [refreshedTime, setLastRefreshedTime] = useState(0);
  // useEffect(() => {
  //   (async () => {
  //     const res = await axios.get(
  //       "http://localhost:8000/api/container_vision/instance/?date=all"
  //     );
  //     const data = res.data;
  //     setInstanceList(data);
  //     setIsLoading(false);
  //   })();
  // }, []);
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(APIStandards.APP.GET_INSTANCES);
      const data = response.data;
      const sortedItems = data.sort((a: any, b: any) => {
        return (
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
      });
      setInstanceList(sortedItems);
      setIsLoading(false);
      setLastRefreshedTime(0);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();

    const intervalId = setInterval(fetchData, 60000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const timerId = setInterval(() => {
      setLastRefreshedTime((prevTime) => prevTime + 1);
    }, 1000);

    return () => clearInterval(timerId);
  }, []);
  return (
    <div
      className={` mx-4 py-10 ${
        pathname.pathname === "/admin/collected-data"
          ? "min-h-[100vh] max-h-[100vh] overflow-y-auto"
          : ""
      }`}
    >
      {isLoading && (
        <div className="flex mx-auto items-center min-h-[85vh] max-h-[85vh] justify-center">
          <Spin />
        </div>
      )}
      {!isLoading && (
        <div className={`flex flex-col gap-5 `}>
          <div className="flex flex-row justify-between">
            <div className=" text-3xl font-bold tracking-wide">
              Instances ({InstanceList.length})
            </div>
            <div className="flex flex-row gap-2 items-center">
              <div>Last Refreshed {refreshedTime}s ago</div>
              <div>
                <Button icon={<RefreshCcw />} onClick={() => fetchData()}>
                  Refresh
                </Button>
              </div>
            </div>
          </div>
          <div>
            {" "}
            <DataTable columns={columns} data={InstanceList} />
          </div>
        </div>
      )}
    </div>
  );
}

export default DataCollectionList;
